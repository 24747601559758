import React from 'react';

import App from './App';
import WeAreRecruiting from './WeAreRecruiting';
import ModernSlaveryAct from './ModernSlaveryAct';

export default function Nav() {
  return window.location.pathname === '/we-are-recruiting' ? (
    <WeAreRecruiting />
  ) : window.location.pathname === '/morden-slavery-act' ? (
    <ModernSlaveryAct />
  ) : (
    <App />
  );
}