export const jobs = [
  {
    title: 'Fertiliser & Nutrition Product Manager',
    date: '08/08/2022',
    content: [
      `Origin’s Amenity Division supplies products and solutions to turf management and landscaping professionals in the UK and export markets. Origin has several key brands including Headland, Rigby Taylor, Symbio, and Turfkeeper, underpinned by a strong manufacturing base including, PB Kent Fertilisers, Linemark UK and N-Virol.\n\n`, 
      `This role is a key position, responsible for the fertiliser and nutrition portfolio of Origin Amenity Solutions.  Key measures of success will be the development, promotion and maintenance of a market leading fertiliser portfolio through a thorough understanding of the amenity fertiliser market.\n\n`,

      ['Main Responsibilities: \n\n', { bold: true }],
      `·   Devise, develop, promote and maintain a market leading fertiliser and nutritional product range and technology concepts for OAS.`,
      `·   Become fully proficient in all current legislation and regulations relating to fertiliser and nutrition products and applications.`,
      `·   Proactively scan the horizon for upcoming legislation changes, market trends, new technologies, concepts and sustainable solutions`,
      `·   Assess possible advantages or implications in the direction of product category development.`,
      `·   Proactively identify opportunities and gaps in marketplace.`,
      `·   Working closely with the R&D function, devise and develop new products or programme concepts with quantifiable performance that meets customer needs.`,
      `·   Create and maintain a sustainability manifesto and action plan, working in conjunction with Group functions to ensure alignment to Group initiatives and translating into local actions.`,
      `·   Create novel solutions to tackle amenity sustainability challenges.`,
      `·   Commercialise R&D outcomes.`,
      `·   Work across the various product groups to develop synergistic approaches and programmes.`,
      `·   Work closely with the Marketing function to launch, promote and maintain OAS product offerings.`,
      `·   Assist the Commercial Director with sales forecasting.`,
      `·   Develop and maintain beneficial supplier relationships in terms of technical interchange, production, supply and quality.`,
      `·   Working with the OAS supply chain negotiate purchase pricing.`,
      `·   Seek to establish yourself as an industry leading professional, presenting at seminars and publishing technical material.`,
      `·   Be a technical educator internally and externally and build strong relationships with OAS customers and sales teams.`,
      `·   As with all roles, demands change over time and therefore the successful applicant may be required to take on additional responsibilities as the demands of the Company dictate.\n\n`,

      ['Skills Required: \n\n', { bold: true }],

      `·   Amenity industry knowledge and experience.`,
      `·   Depth and breadth of knowledge of amenity fertiliser and nutritional products, their applications, agronomy, future trends, supply base, competing products, market positioning, promotion & marketing, technical requirements and legislation.`,
      `·   Excellent verbal, written communication, and presentation skills.`,
      `·   Analytical with great attention to detail.`,
      `·   Excellent planning and organisational skills.`,
      `·   Computer literate, able to assemble and disseminate information and reports from IT systems.`,
      `·   Commercially aware.`,
      `·   Approachable and able to build strong working relationships.`,
      `·   Able to manage own workload, be self-motivated and driven.`,

      [`<p style="font-weight: bold;">If you would like to apply for this position, please submit a CV and covering letter to <a href="mailto:recruitment@originukoperations.co.uk">recruitment@originukoperations.co.uk</a></p>`, { type: 'html' }]
    ]
  }
]