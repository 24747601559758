import React, { useMemo, useState, useRef, useEffect } from 'react';
import { Col, Img, Row, Text, useDynamicResponsiveValue, Scroll } from 'react-quick-style-components';
import { useWindowDimensions, StyleSheet, Image } from 'react-native';
import { ICON, IMG } from 'assets';
import { COLOR } from 'const';
import { RatioCol } from 'components';
import Slider from "react-slick";

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const App = () => {
  const rV = useDynamicResponsiveValue();
  const { width, height } = useWindowDimensions();
  const images = [
    IMG.SLIDER_1,
    IMG.SLIDER_2,
    IMG.SLIDER_3,
    IMG.SLIDER_4,
    IMG.SLIDER_5,
    IMG.SLIDER_6,
    IMG.SLIDER_7,
  ];

  const slideSize = {
    width: width,
    height: 392 * width / 1920,
  };
  const freshCircleSize = {
    height: (slideSize.height - 70) * 2,
    width: (slideSize.height - 70) * 2,
  }

  const columnHeiht= rV({ xs: undefined, md: 770, lg: 790 });

  const imageMonth = () => {
    const currentMonth = new Date().getMonth() + 1;
    return IMG['MONTH_'+currentMonth] ? IMG['MONTH_'+currentMonth] : IMG.MONTH_7;
  };

  return (
    <Col flex1>
      <Scroll flex1>
        {/* HEADER */}
        <Col paddingVertical10 paddingHorizontal={rV({ xs: 10, md: 30, xl: 75 })}>
          <Img source={IMG.ORIGIN_LOGO} style={rV({ xs: { width: 195 / 2, height: 126 / 2 }, md: { width: 195 * 0.7, height: 126 * 0.7 } })} />
        </Col>
        {/* SLIDER */}
        <Col>
          {/* <Slider
            {...settings}
          >
            {images.map((val, i) => (
              <Col width={slideSize.width} height={slideSize.height} key={'image-' + i}>
                <Img source={val} style={styles.imageSize} resizeMode="cover" />
                <Col absoluteFill backgroundColor={"rgba(0,0,0,0.1)"} middle>
                  <Text center fontWeight="bold" colorWhite fontSize={32}>Welcome to Origin Amenity Solutions</Text>
                </Col>
              </Col>
            ))}
          </Slider> */}
          <Col width={slideSize.width} height={slideSize.height} overflow="hidden">
            {/* <Img source={imageMonth()} style={styles.imageSize} resizeMode="cover" /> */}
            <img
              src={imageMonth()}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'bottom',
              }}
            />
            <Col absoluteFill backgroundColor={"rgba(0,0,0,0.1)"} middle>
              <Text center fontWeight="bold" colorWhite fontSize={32}>Welcome to Origin Amenity Solutions</Text>
            </Col>
          </Col>
          <Col absolute bottom={- 230 * freshCircleSize.height / 578} right={-30} width={freshCircleSize.width} height={freshCircleSize.height}>
            <Img source={IMG.FRESH_CIRCLE} style={styles.imageFreshCircle} resizeMode="cover" />
          </Col>
        </Col>
        <Col width="75%" alignSelf="center" marginTop20>
          <Text center color="black" fontWeight="bold" fontSize24>Combining the strength of industry leading companies and their products, together with a technology led research and development facility, Origin Amenity Solutions is a leading force in the UK amenity industry.</Text>
        </Col>
        <Col padding10 marginVertical20>
          <Text center fontSize20>Working together, but retaining their individual identity and areas of expertise, the group companies offer the widest, most comprehensive choice for achieving success across all aspects of plant health; its growth, protection, appearance, control and sustainability. {'\n'}{'\n'}

            From products to people; Origin Amenity Solutions has the largest UK, field based force of over 70 highly skilled and knowledgeable individuals, supported by a team of specialist Product Managers. Such support provides advice on product selection, programming, application and effectiveness. This is further backed up with soil analysis, weed, pest and disease identification and water management. {'\n'}{'\n'}

            Origin Amenity Solutions, with its own UK based Turf Technology Centre can undertake its own research, development and trials to identify, assess and demonstrate the benefits of both existing and future products and technologies under UK conditions and practices. {'\n'}{'\n'}

            In addition, the group can call upon the expertise from other parts of the Origin group including fertilizer manufacturing, speciality product formulation and supply and line marking machinery and paint manufacturing.
          </Text>
        </Col>
        <Col width="75%" alignSelf="center" marginTop20>
          <Text center colorMain fontWeight="bold" fontSize24>A force in the pursuit of excellence</Text>
        </Col>

        <Row xs='100%' md='50%' lg='25%' marginVertical60 marginBottom={25} padding15 alignItems="flex-start">
          <Col margin15 backgroundColor="#51974F" paddingHorizontal15 paddingVertical30 height={columnHeiht} borderRadius13>
            <RatioCol width="100%" ratio={1} bgWhite borderRadius12 onPress={() => window.open('http://www.headlandamenity.com', '_blank')}>
              <Col flex1 middle>
                <Img source={IMG.HEADLAND_LOGO} style={styles.image80Size} resizeMode="contain" />
              </Col>
            </RatioCol>
            <Text center  marginTop30 colorWhite fontWeight="bold" fontSize={rV({xs : 14, lg: 16, xxl: 16})}>For over 25 years, Headland Amenity has been a leading innovator in the field of speciality fertilisers and chemicals as well as biostimulants, wetting agents and non-pesticidal turf disease management. We supply and provide technical support to the golf and sports turf markets as well as lawn care and vegetation management professionals.</Text>

            <Col flex1 marginTop30 justifyContent="flex-end">
              <Row paddingVertical={7.5} marginTop30 onPress={() => window.open('http://www.headlandamenity.com', '_blank')}>
                <Col><Img source={IMG.LINK} style={styles.icon} resizeMode="contain" /></Col>
                <Text marginLeft15 colorWhite>www.headlandamenity.com/</Text>
              </Row>
              <Row paddingVertical={7.5} onPress={() => window.open('mailto:info@headlandamenity.com')}>
                <Col><Img source={IMG.EMAIL} style={styles.icon} resizeMode="contain" /></Col>
                <Text marginLeft15 colorWhite>info@headlandamenity.com</Text>
              </Row>
              <Row paddingVertical={7.5} onPress={() => window.open('tel:01763 255550')}>
                <Col><Img source={IMG.PHONE} style={styles.icon} resizeMode="contain" /></Col>
                <Text marginLeft15 colorWhite>+44 (0) 1763 255550</Text>
              </Row>
            </Col>
            
          </Col>

          <Col margin15 backgroundColor="#8BB644" paddingHorizontal15 paddingVertical30 height={columnHeiht} borderRadius13>
            <RatioCol width="100%" ratio={1} bgWhite borderRadius12 onPress={() => window.open('http://www.rigbytaylor.com', '_blank')}>
              <Col flex1 middle>
                <Img source={IMG.TAYLOR_LOGO} style={styles.image90Size} resizeMode="contain" />
              </Col>
            </RatioCol>
            <Text center  marginTop30 colorWhite fontWeight="bold" fontSize={rV({xs : 14, lg: 16, xxl: 16})}>A leading, innovative company, Rigby Taylor has been for over a century the supplier of quality products for the health, protection, presentation and performance of sports, recreational and landscaped surfaces. The company is a forerunner in vegetation control, a pioneer in robotic line marking and combines leading edge technologies with traditional agronomic practices.</Text>
            
            <Col flex1 marginTop30 justifyContent="flex-end">
              <Row paddingVertical={7.5} onPress={() => window.open('http://www.rigbytaylor.com', '_blank')}>
                <Col><Img source={IMG.LINK} style={styles.icon} resizeMode="contain" /></Col>
                <Text marginLeft15 colorWhite>www.rigbytaylor.com/</Text>
              </Row>
              <Row paddingVertical={7.5} onPress={() => window.open('mailto:sales@rigbytaylor.com')}>
                <Col><Img source={IMG.EMAIL} style={styles.icon} resizeMode="contain" /></Col>
                <Text marginLeft15 colorWhite>sales@rigbytaylor.com</Text>
              </Row>
              <Row paddingVertical={7.5} onPress={() => window.open('tel:0800 424 919')}>
                <Col><Img source={IMG.PHONE} style={styles.icon} resizeMode="contain" /></Col>
                <Text marginLeft15 colorWhite>+44 (0) 800 424 919</Text>
              </Row>
            </Col>
            
          </Col>

          <Col margin15 backgroundColor="#51974F" paddingHorizontal15 paddingVertical30 height={columnHeiht} borderRadius13>
            <RatioCol width="100%" ratio={1} bgWhite borderRadius12 onPress={() => window.open('http://www.symbio.co.uk/', '_blank')}>
              <Col flex1 middle>
                <Img source={IMG.SYMBIO_LOGO} style={styles.image60Size} resizeMode="contain" />
              </Col>
            </RatioCol>
            <Text center  marginTop30 colorWhite fontWeight="bold" fontSize={rV({xs : 14, lg: 16, xxl: 16})}>For over 30 years Symbio has specialised in selecting and applying the mycorrhizae, soil bacteria, fungi and the organisms found in a healthy soil food web to help our customers and consultants grow strong, healthy, nutritious plants with minimal use of inorganic fertilisers and pesticides in cost effective IPM programmes.</Text>

            <Col flex1 marginTop30 justifyContent="flex-end">
              <Row paddingVertical={7.5} marginTop30 onPress={() => window.open('http://www.symbio.co.uk/', '_blank')}>
                <Col><Img source={IMG.LINK} style={styles.icon} resizeMode="contain" /></Col>
                <Text marginLeft15 colorWhite>www.symbio.co.uk/</Text>
              </Row>
              <Row paddingVertical={7.5} onPress={() => window.open('mailto:info@symbio.co.uk')}>
                <Col><Img source={IMG.EMAIL} style={styles.icon} resizeMode="contain" /></Col>
                <Text marginLeft15 colorWhite>info@symbio.co.uk</Text>
              </Row>
              <Row paddingVertical={7.5} onPress={() => window.open('tel:01428 685762')}>
                <Col><Img source={IMG.PHONE} style={styles.icon} resizeMode="contain" /></Col>
                <Text marginLeft15 colorWhite>+44 (0) 1428 685762</Text>
              </Row>
            </Col>
            
          </Col>

          <Col margin15 backgroundColor="#8BB644" paddingHorizontal15 paddingVertical30 height={columnHeiht} borderRadius13>
            <RatioCol width="100%" ratio={1} bgWhite borderRadius12 onPress={() => window.open('http://www.turfkeeper.com/', '_blank')}>
              <Col flex1 middle>
                <Img source={IMG.TURFKEEPER_LOGO} style={styles.image90Size} resizeMode="contain" />
              </Col>
            </RatioCol>
            <Text center  marginTop30 colorWhite fontWeight="bold" fontSize={rV({xs : 14, lg: 16, xxl: 16})}>The Industry’s leading online management system. TurfKeeper is a single, personalised and fully inclusive platform to assist turf managers. As an integral part of hundreds of turf operations around the world, the system provides complete oversight & insight into staff management, turf maintenance practices, machinery and chemical applications and department budgeting.</Text>

            <Col flex1 marginTop30 justifyContent="flex-end">
              <Row paddingVertical={7.5} marginTop30 onPress={() => window.open('http://www.turfkeeper.com/', '_blank')}>
                <Col><Img source={IMG.LINK} style={styles.icon} resizeMode="contain" /></Col>
                <Text marginLeft15 colorWhite>www.turfkeeper.com/</Text>
              </Row>
              <Row paddingVertical={7.5} onPress={() => window.open('mailto:support@turfkeeper.com')}>
                <Col><Img source={IMG.EMAIL} style={styles.icon} resizeMode="contain" /></Col>
                <Text marginLeft15 colorWhite>support@turfkeeper.com</Text>
              </Row>
              <Row paddingVertical={7.5} onPress={() => window.open('tel:0785 063 8508')}>
                <Col><Img source={IMG.PHONE} style={styles.icon} resizeMode="contain" /></Col>
                <Text marginLeft15 colorWhite>+44 (0) 785 063 8508</Text>
              </Row>
            </Col>
            
          </Col>
        </Row>
          
        <a href="/we-are-recruiting" style={{ textDecoration: 'none' }}>
          <Row backgroundColor="#4296CB" borderRadius={13} margin={25} marginTop={0} marginBottom={60} padding={40} paddingHorizontal={'5%'}>
            <Text bold fontSize={30} colorWhite>WE ARE RECRUITING!</Text>
            <Text colorWhite marginLeft={30} fontSize={30}>Find your perfect career move….</Text>
          </Row>
        </a>

        <Row bgMain height40 paddingHorizontal15 justifyContent="space-between">
          <Text colorWhite bold>Copyright 2021 Origin Amenity Solutions. All Rights Reserved</Text>
          <a href="/morden-slavery-act">
            <Text colorWhite textDecorationLine="underline">Modern Slavery Act</Text>
          </a>
          
        </Row>
      </Scroll>
    </Col>
  );
};

const styles = StyleSheet.create({
  imageSize: {
    width: '100%',
    height: '100%',
  },
  icon: {
    width: 20,
    height: 20,
  },
  image90Size: {
    width: '90%',
    height: '90%',
  },
  image80Size: {
    width: '80%',
    height: '80%',
  },
  image60Size: {
    width: '60%',
    height: '60%',
  },
  imageFreshCircle: {
    width: '100%',
    height: '100%',
    opacity: 1,
  },
});

export default App;