import React, { useMemo, useState, useRef, useEffect } from 'react';
import { Col, Img, Row, Text, useDynamicResponsiveValue, Scroll } from 'react-quick-style-components';
import { useWindowDimensions, StyleSheet, Image } from 'react-native';
import { ICON, IMG } from 'assets';
import { COLOR } from 'const';
import { RatioCol } from 'components';
import Slider from "react-slick";

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const App = () => {
  const rV = useDynamicResponsiveValue();
  const { width, height } = useWindowDimensions();
  const images = [
    IMG.SLIDER_1,
    IMG.SLIDER_2,
    IMG.SLIDER_3,
    IMG.SLIDER_4,
    IMG.SLIDER_5,
    IMG.SLIDER_6,
    IMG.SLIDER_7,
  ];

  const slideSize = {
    width: width,
    height: 392 * width / 1920,
  };
  const freshCircleSize = {
    height: (slideSize.height - 70) * 2,
    width: (slideSize.height - 70) * 2,
  }

  const columnHeiht= rV({ xs: undefined, md: 770, lg: 790 });

  const imageMonth = () => {
    const currentMonth = new Date().getMonth() + 1;
    return IMG['MONTH_'+currentMonth] ? IMG['MONTH_'+currentMonth] : IMG.MONTH_7;
  };

  const links = [
    {
      title: 'Modern Slavery Act - 2021',
      link: '/Modern Slavery Act Statement_OAS.pdf',
    },
    {
      title: 'Modern Slavery Act - 2022',
      link: '/Modern Slavery Act Statement_OAS 2022_54_.pdf',
    },
    {
      title: 'Modern Slavery Act - 2023',
      link: '/Modern Slavery Act Statement_OAS 2023_73_.pdf',
    }
  ]

  const renderListJobs = () => {
    return (
      <Col width="80%" alignSelf="center">
        {links.map((link, i) => (
          <Col key={'job-'+i} marginBottom={10} onPress={() => window.open(link.link, '_blank')}>
            <Text fontSize={22} color='#4E2A7E' textDecorationLine='underline'>{link.title}</Text>
          </Col>
        ))}
      </Col>
    );
  }

  return (
    <Col flex1>
      <Scroll flex1>
        {/* HEADER */}
        <Col paddingVertical10 paddingHorizontal={rV({ xs: 10, md: 30, xl: 75 })}>
          <a href="/">
            <Img source={IMG.ORIGIN_LOGO} style={rV({ xs: { width: 195 / 2, height: 126 / 2 }, md: { width: 195 * 0.7, height: 126 * 0.7 } })} />
          </a>
        </Col>
        {/* SLIDER */}
        <Col>
          {/* <Slider
            {...settings}
          >
            {images.map((val, i) => (
              <Col width={slideSize.width} height={slideSize.height} key={'image-' + i}>
                <Img source={val} style={styles.imageSize} resizeMode="cover" />
                <Col absoluteFill backgroundColor={"rgba(0,0,0,0.1)"} middle>
                  <Text center fontWeight="bold" colorWhite fontSize={32}>Welcome to Origin Amenity Solutions</Text>
                </Col>
              </Col>
            ))}
          </Slider> */}
          <Col width={slideSize.width} height={slideSize.height} overflow="hidden">
            {/* <Img source={imageMonth()} style={styles.imageSize} resizeMode="cover" /> */}
            <img
              src={imageMonth()}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'bottom',
              }}
            />
            <Col absoluteFill backgroundColor={"rgba(0,0,0,0.1)"} middle>
              <Text center fontWeight="bold" colorWhite fontSize={32}>Welcome to Origin Amenity Solutions</Text>
            </Col>
          </Col>
          <Col absolute bottom={- 230 * freshCircleSize.height / 578} right={-30} width={freshCircleSize.width} height={freshCircleSize.height}>
            <Img source={IMG.FRESH_CIRCLE} style={styles.imageFreshCircle} resizeMode="cover" />
          </Col>
        </Col>
        <Col width="75%" alignSelf="center" marginTop20>
          <Text center colorMain fontWeight="bold" fontSize30>{'Modern Slavery Act'.toUpperCase()}</Text>
        </Col>

        <Col marginTop80>
          {renderListJobs()}
        </Col>

        <Col flex1 />

        <Row bgMain height40 paddingHorizontal15 justifyContent="space-between">
          <Text colorWhite bold>Copyright 2021 Origin Amenity Solutions. All Rights Reserved</Text>
          <Text colorWhite textDecorationLine="underline" onPress={() => window.location.pathname = '/'}>Back to Home</Text>
        </Row>
      </Scroll>
    </Col>
  );
};

const styles = StyleSheet.create({
  imageSize: {
    width: '100%',
    height: '100%',
  },
  icon: {
    width: 20,
    height: 20,
  },
  image90Size: {
    width: '90%',
    height: '90%',
  },
  image80Size: {
    width: '80%',
    height: '80%',
  },
  image60Size: {
    width: '60%',
    height: '60%',
  },
  imageFreshCircle: {
    width: '100%',
    height: '100%',
    opacity: 1,
  },
});

export default App;