import { initQuickStyle } from 'react-quick-style-components';
import { COLOR } from './color';

initQuickStyle.setFontStyle({
  fontFamily: 'Montserrat',
  color: COLOR.FONT,
});

initQuickStyle.setMainColor(COLOR.MAIN);

initQuickStyle.setAdditionStyles({
  bold: {
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
  },
  center: {
    textAlign: 'center',
  },
  shadow: {
    shadowColor: 'rgba(0,0,0,0.1)',
    shadowOffset: {
      width: 1,
      height: 1
    },
    shadowOpacity: 1,
    shadowRadius: 8,
    elevation: 2,
  },
});