import React, { memo } from 'react';
import { Col, Row, Text, usePropsStyle } from 'react-quick-style-components';
import SelectWeb from 'react-select';
import { COLOR } from 'const';

interface ISelectProps {
  label: string,
  required?: boolean,
  value: any,
  onChange: any,
  options: Array<any>,
  [additionProp: string]: any,
}

const Select = (props : ISelectProps) => {
  const propsStyle = usePropsStyle(props);
  return (
    <Col style={propsStyle}>
      {Boolean(props.label) && (
        <Row marginBottom8>
          <Col flex1>
            <Text subtitle1>{props.label} {Boolean(props.required) && <Text color="red"> *</Text>}</Text>
          </Col>
        </Row>
      )}
      <SelectWeb
        value={props.value}
        onChange={props.onChange}
        styles={{
          control: (style, state) => {
            return {
              ...style,
              color: 'white',
              backgroundColor: 'white',
              height: 60,
              borderRadius: 10,
              borderColor: 'white',
              boxShadow: 'none',
              '&:hover': {
                borderColor: 'white'
              }
            };
          },
          placeholder: style => ({ ...style, color: 'rgba(0,0,0,0.7)', paddingLeft: 10, fontSize: 20, }),
          input: styles => ({ ...styles, color: COLOR.FONT, outline: 'none', paddingLeft: 10, fontSize: 20, }),
          option: (style, { isFocused }) => ({ ...style, color: COLOR.FONT, paddingLeft: 10, fontSize: 20, }),
          menu: (style) => ({ ...style, border: '1px solid white' }),
          singleValue: (style) => ({ ...style, color: COLOR.FONT, paddingLeft: 10, fontSize: 20, }),
          dropdownIndicator: (style) => ({
            ...style,
            color: COLOR.FONT,
            '&:hover': {
              color: COLOR.FONT
            }
          }),
          indicatorSeparator: (style) => ({ display: 'none' }),
        }}
        menuPortalTarget={document.querySelector('body')}
        {...props}
      />
    </Col>
  );
};

export default memo(Select);