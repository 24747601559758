import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { StyleSheet, Text, View, Platform } from 'react-native';
import './src/const/style';

(() => {
  if (Platform.OS === 'web') {
    const fontCss = `

      @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

      html, body {
        font-family: 'Montserrat', sans-serif;
      }
      
      .slick-vertical .slick-slide {
        border: none;
      }
    `;

    const style = document.createElement('style')
    style.textContent = fontCss;
    document.head.append(style);
  }
})();

export { default } from './src/screens/Nav'; 

