import React, { useMemo, useState, useRef, useEffect } from 'react';
import { Col, Img, Row, Text, useDynamicResponsiveValue, Scroll } from 'react-quick-style-components';
import { useWindowDimensions, StyleSheet, Image, TextInput } from 'react-native';
import { ICON, IMG } from 'assets';
import { COLOR } from 'const';
import { RatioCol, Select } from 'components';
import Slider from "react-slick";

import { jobs } from './jobs';

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const App = () => {
  const [currentJobIndex, setCurrentJobIndex] = useState(-1);
  const job = jobs[currentJobIndex];
  const rV = useDynamicResponsiveValue();
  const { width, height } = useWindowDimensions();
  const images = [
    IMG.SLIDER_1,
    IMG.SLIDER_2,
    IMG.SLIDER_3,
    IMG.SLIDER_4,
    IMG.SLIDER_5,
    IMG.SLIDER_6,
    IMG.SLIDER_7,
  ];
  const [form, setForm] = useState({
    name: '',
    email: '',
    business: '',
    interestedIn: '',
  })

  const slideSize = {
    width: width,
    height: 392 * width / 1920,
  };
  const freshCircleSize = {
    height: (slideSize.height - 70) * 2,
    width: (slideSize.height - 70) * 2,
  }

  const selectOptions = [
    { value: 'Receiving Details of Job Opportunities', label: 'Receiving Details of Job Opportunities' },
    { value: 'Invitations to Open Days', label: 'Invitations to Open Days' },
    { value: 'Up-date Emails from Origin Amenity Solutions', label: 'Up-date Emails from Origin Amenity Solutions' },
  ]

  const imageMonth = () => {
    const currentMonth = new Date().getMonth() + 1;
    return IMG['MONTH_' + currentMonth] ? IMG['MONTH_' + currentMonth] : IMG.MONTH_7;
  };

  const submit = async () => {
    if (!form.name || !form.email || !form.business || !form.interestedIn) return alert('Please fill in all fields');
    fetch('https://docs.google.com/forms/d/e/1FAIpQLSddHlEVuNbDxVUU6aAe8oRmZ3JA2WQGFM-fg0sLM7SjMBURSA/formResponse', {
      method: 'POST',
      headers: {
        'Content-Type': "application/x-www-form-urlencoded;charset=utf-8"
      },
      body: `entry.207606177=${encodeURI(form.name)}&entry.1194596834=${form.email}&entry.764364755=${encodeURI(form.business)}&entry.105713047=${form.interestedIn}`,
    });
    alert('Thank you, we have received your submission');
  };

  return (
    <Col flex1>
      <Scroll flex1>
        {/* HEADER */}
        <Col paddingVertical10 paddingHorizontal={rV({ xs: 10, md: 30, xl: 75 })}>
          <a href="/">
            <Img source={IMG.ORIGIN_LOGO} style={rV({ xs: { width: 195 / 2, height: 126 / 2 }, md: { width: 195 * 0.7, height: 126 * 0.7 } })} />
          </a>
        </Col>
        {/* SLIDER */}
        <Col>
          {/* <Slider
            {...settings}
          >
            {images.map((val, i) => (
              <Col width={slideSize.width} height={slideSize.height} key={'image-' + i}>
                <Img source={val} style={styles.imageSize} resizeMode="cover" />
                <Col absoluteFill backgroundColor={"rgba(0,0,0,0.1)"} middle>
                  <Text center fontWeight="bold" colorWhite fontSize={32}>Welcome to Origin Amenity Solutions</Text>
                </Col>
              </Col>
            ))}
          </Slider> */}
          <Col width={slideSize.width} height={slideSize.height} overflow="hidden">
            {/* <Img source={imageMonth()} style={styles.imageSize} resizeMode="cover" /> */}
            <img
              src={imageMonth()}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'bottom',
              }}
            />
            <Col absoluteFill backgroundColor={"rgba(0,0,0,0.1)"} middle>
              <Text center fontWeight="bold" colorWhite fontSize={32}>Welcome to Origin Amenity Solutions</Text>
            </Col>
          </Col>
          <Col absolute bottom={- 230 * freshCircleSize.height / 578} right={-30} width={freshCircleSize.width} height={freshCircleSize.height}>
            <Img source={IMG.FRESH_CIRCLE} style={styles.imageFreshCircle} resizeMode="cover" />
          </Col>
        </Col>
        <Col width="75%" alignSelf="center" marginTop20>
          <Text center colorMain fontWeight="bold" fontSize30>{job ? job.title : 'WE ARE RECRUITING!'}</Text>
        </Col>

        <Col marginTop50>
          <Col width="75%" alignSelf="center">
            <Text center colorMain fontWeight="bold" fontSize30>Careers</Text>
          </Col>
          <Col width="75%" alignSelf="center" marginTop30>
            <Text color="black" fontWeight="bold" fontSize20>Origin Amenity Solutions is the UK's leading supplier of innovative products and solutions for the turf, amenity and grounds maintenance sector.</Text>
            <Text marginTop20>
              Formed in 2021 from four leading amenity brands, Headland Amenity, Rigby Taylor, Symbio and Turfkeeper, OAS combines the knowledge, experience, technology and product innovation of these brands to offer the industry a portfolio of products and solutions for the future.
              {'\n'}
              {'\n'}
              The team behind OAS are paramount to the success of the business, each has a wealth of knowledge of the day-to-day workings of the turf and amenity industry. Whether you are preparing a golf tee area, specifying wildflowers for a public space or managing a team of greenkeepers, OAS has the experience to assist. Our teams are qualified with both BASIS and FACTS and have worked with greenkeepers and grounds managers across the UK developing bespoke maintenance programmes that are proven to succeed and offer long-term solutions.
              {'\n'}
              {'\n'}
              At OAS we employ a team of 100+ specialists in the areas of turf management, grounds management, research and development, finance, operations, warehousing, marketing and product management. Our culture is built on the foundations of education and product innovation for the future.
            </Text>
            <Row marginTop20 xs='100%' md='33%' marginHorizontal={-20}>
              <Col margin10 padding10>
                <RatioCol width={'100%'} ratio={504 / 378}>
                  <Image source={IMG.RECRUIT_1} style={{ width: '100%', height: '100%' }} />
                </RatioCol>
              </Col>
              <Col margin10 padding10>
                <RatioCol width={'100%'} ratio={504 / 378}>
                  <Image source={IMG.RECRUIT_2} style={{ width: '100%', height: '100%' }} />
                </RatioCol>
              </Col>
              <Col margin10 padding10>
                <RatioCol width={'100%'} ratio={504 / 378}>
                  <Image source={IMG.RECRUIT_3} style={{ width: '100%', height: '100%' }} />
                </RatioCol>
              </Col>
            </Row>
          </Col>
          <Col width="75%" alignSelf="center" marginTop30>
            <Text center colorMain fontWeight="bold" fontSize30>Reach out</Text>
          </Col>
          <Col width="75%" alignSelf="center" marginTop20>
            <Text>
              If you are looking to further your career in the turf industry or would like an insight into how we work as a business, then please reach out. We are a growing business with a firm strategy for the future and we would love to hear from you.
              Please complete the form below and register your interest in receiving job opportunities, details of Open Days at our Turf Science and Research Centre in Essex and general up-dates from Origin Amenity Solutions.
            </Text>
          </Col>
          <Col width="70%" alignSelf="center" marginTop20 backgroundColor='#8BB644' borderRadius10 padding20 marginTop30>
            <Row xs='100%' md='50%'>
              <Col padding10 margin10>
                <TextInput
                  value={form.name}
                  onChangeText={v => setForm({ ...form, name: v })}
                  style={{
                    width: '100%',
                    height: 60,
                    borderRadius: 10,
                    backgroundColor: 'white',
                    paddingHorizontal: 20,
                    fontSize: 20,
                  }}
                  placeholder='Name'
                />
              </Col>
              <Col padding10 margin10>
                <TextInput
                  value={form.email}
                  onChangeText={v => setForm({ ...form, email: v })}
                  style={{
                    width: '100%',
                    height: 60,
                    borderRadius: 10,
                    backgroundColor: 'white',
                    paddingHorizontal: 20,
                    fontSize: 20,
                  }}
                  placeholder='Email'
                />
              </Col>
              <Col padding10 margin10>
                <TextInput
                  value={form.business}
                  onChangeText={v => setForm({ ...form, business: v })}
                  style={{
                    width: '100%',
                    height: 60,
                    borderRadius: 10,
                    backgroundColor: 'white',
                    paddingHorizontal: 20,
                    fontSize: 20,
                  }}
                  placeholder='Business/College/School'
                />
              </Col>
              <Col padding10 margin10>
                <Select
                  options={selectOptions}
                  value={selectOptions.find(v => v.value === form.interestedIn)}
                  onChange={data => setForm({ ...form, interestedIn: data.value })}
                  label=''
                  placeholder='I am interested in….'
                />
              </Col>
              <Col padding10 margin10></Col>
              <Col padding10 margin10 alignItems='flex-end'>
                <Col height={60} borderRadius10 bgWhite width={150} middle onPress={submit}>
                  <Text colorMain bold fontSize20>Submit</Text>
                </Col>
              </Col>
            </Row>
            
          </Col>

          <Row alignSelf="center" marginVertical30>
            <RatioCol width={'24.5vw'} ratio={472 / 629} marginRight30>
              <Image source={IMG.RECRUIT_4} style={{ width: '100%', height: '100%' }} />
            </RatioCol>
            <RatioCol width={'30vw'} ratio={577 / 629}>
              <Image source={IMG.RECRUIT_5} style={{ width: '100%', height: '100%' }} />
            </RatioCol>
          </Row>  
        </Col>

        <Col flex1 />

        <Row bgMain height40 paddingHorizontal15 justifyContent="space-between">
          <Text colorWhite bold>Copyright 2021 Origin Amenity Solutions. All Rights Reserved</Text>
          <Text colorWhite textDecorationLine="underline" onPress={() => window.open('/Modern Slavery Act Statement_OAS.pdf')}>Modern Slavery Act</Text>
        </Row>
      </Scroll>
    </Col>
  );
};

const styles = StyleSheet.create({
  imageSize: {
    width: '100%',
    height: '100%',
  },
  icon: {
    width: 20,
    height: 20,
  },
  image90Size: {
    width: '90%',
    height: '90%',
  },
  image80Size: {
    width: '80%',
    height: '80%',
  },
  image60Size: {
    width: '60%',
    height: '60%',
  },
  imageFreshCircle: {
    width: '100%',
    height: '100%',
    opacity: 1,
  },
});

export default App;