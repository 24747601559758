

const ORIGIN_LOGO = require('./origin_logo.png');
const SLIDER_1 = require('./sliders/1_Sports field_iStock-174946107.jpg');
const SLIDER_2 = require('./sliders/2_Golf.jpg');
const SLIDER_3 = require('./sliders/3_Cricket_iStock-144227727.jpg');
const SLIDER_4 = require('./sliders/4_Tennis_iStock-92036167.jpg');
const SLIDER_5 = require('./sliders/5_Great Yarmouth Racecourse .jpg');
const SLIDER_6 = require('./sliders/6_Bowls.jpg');
const SLIDER_7 = require('./sliders/7_Wildflower_poppy.jpg');


const MONTH_7 = require('./sliders/months/1_July_Sports field_iStock-174946107-min.jpg');
const MONTH_8 = require('./sliders/months/1_July_Sports field_iStock-174946107-min.jpg');
const MONTH_9 = require('./sliders/months/2_September_Golf-min.jpg');
const MONTH_10 = require('./sliders/months/2_September_Golf-min.jpg');
const MONTH_11 = require('./sliders/months/3_November_Cricket_iStock-144227727-min.jpg');
const MONTH_12 = require('./sliders/months/3_November_Cricket_iStock-144227727-min.jpg');
const MONTH_1 = require('./sliders/months/4_January_Tennis_iStock-92036167-min.jpg');
const MONTH_2 = require('./sliders/months/4_January_Tennis_iStock-92036167-min.jpg');
const MONTH_3 = require('./sliders/months/5_March_Great_Yarmouth Racecourse -min.jpg');
const MONTH_4 = require('./sliders/months/5_March_Great_Yarmouth Racecourse -min.jpg');
const MONTH_5 = require('./sliders/months/6_May_Bowls-min.jpg');
const MONTH_6 = require('./sliders/months/6_May_Bowls-min.jpg');

const FRESH_CIRCLE = require('./origin_fresh_green_circle.png');

const HEADLAND_LOGO = require('./Headland_logo_large.jpg');
const TAYLOR_LOGO = require('./Rigby Taylor_LOGO.png');
const SYMBIO_LOGO = require('./Symbio_Logo_64_.png');
const TURFKEEPER_LOGO = require('./Turfkeeper logo.jpg');

const RECRUIT_1 = require('./recruit_img1.png');
const RECRUIT_2 = require('./recruit_img2.png');
const RECRUIT_3 = require('./recruit_img3.png');
const RECRUIT_4 = require('./recruit_img4.png');
const RECRUIT_5 = require('./recruit_img5.png');


const LINK = require('./link.svg');
const EMAIL = require('./email.svg');
const PHONE = require('./call.svg');


export const ICON = {
  
};

export const IMG = {

  ORIGIN_LOGO,
  SLIDER_1,
  SLIDER_2,
  SLIDER_3,
  SLIDER_4,
  SLIDER_5,
  SLIDER_6,
  SLIDER_7,
  FRESH_CIRCLE,
  HEADLAND_LOGO,
  TAYLOR_LOGO,
  SYMBIO_LOGO,
  TURFKEEPER_LOGO,
  LINK,
  EMAIL,
  PHONE,
  MONTH_1,
  MONTH_2,
  MONTH_3,
  MONTH_4,
  MONTH_5,
  MONTH_6,
  MONTH_7,
  MONTH_8,
  MONTH_9,
  MONTH_10,
  MONTH_11,
  MONTH_12,

  RECRUIT_1,
  RECRUIT_2,
  RECRUIT_3,
  RECRUIT_4,
  RECRUIT_5,
};
